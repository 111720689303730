<template>
  <div>
    <h3>
      <div><span>{{category1}}•</span></div>
      <div class="right">{{category2}}</div>
    </h3>
    <div class="subtitle"><span :class="iscloud?'fz26':'fz20'">{{subtitle}}</span></div>
  </div>
</template>
<script>
export default {
  name: 'TechTitle',
  props:['category1','category2','subtitle','iscloud'],
  data() {
    return {};
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
  font-size: 36px;
}
h3 > div > span{
  color:#1046a2;
}
h3 > div {
  display: inline-block;
  padding-left: 124px;
  position: relative;
  background: url('../../../assets/img/circle-left.png') no-repeat left center;
  background-size: contain;
}
h3 > div.right{
  padding-left: 0px;
  padding-right: 120px;
  background: url('../../../assets/img/circle-right.png') no-repeat right center;
  background-size: contain;
}
.subtitle {
  text-align: center;
}
.subtitle span{
  display: inline-block;
  background: linear-gradient(90deg,#2f4dc6,#bb14d0);
  color: #fff;
  margin-top: 10px;
  padding:0 10px;
}
.fz26{
  font-size: 26px;
}
.fz20{
  font-size: 20px;
}
</style>
